import moment from 'moment'
import { Offer } from '../pages/offers/types'

export const format2Decimal = (amount: number) => {
  if (amount) {
    return '$' + Number(amount).toFixed(2)
  } else {
    return '$0.00'
  }
}

export const toDisplayHoursOrMinutes = (minutes: number) => {
  if (minutes) {
    const mins = minutes % 60
    const hours = Math.floor(minutes / 60)
    if (hours && mins) {
      return '0 mins'
    } else if (hours) {
      return hours + (hours > 1 ? ' hrs' : 'hr')
    } else if (mins) {
      return mins + ' mins'
    }
  } else {
    return '0 mins'
  }
}

export const formatDate = (date: string | null, format: string) => {
  if (date) {
    return moment(date).format(format)
  } else {
    return ''
  }
}

export const displayDayString = (offer: Offer) => {
  let displayString: string = ''
  var added: boolean = false

  if (offer.validMonday) {
    displayString += 'Mo '
    added = true
  }

  if (offer.validTuesday) {
    displayString += checkAddedAndAppend(added, 'Tu')
    added = true
  }

  if (offer.validWednesday) {
    displayString += checkAddedAndAppend(added, 'We')
    added = true
  }

  if (offer.validThursday) {
    displayString += checkAddedAndAppend(added, 'Th')
    added = true
  }

  if (offer.validFriday) {
    displayString += checkAddedAndAppend(added, 'Fr')
    added = true
  }

  if (offer.validSaturday) {
    displayString += checkAddedAndAppend(added, 'Sa')
    added = true
  }

  if (offer.validSunday) {
    displayString += checkAddedAndAppend(added, 'Su')
  }

  return displayString
}

const checkAddedAndAppend = (added: boolean, dayofWeek: string) => {
  if (added) {
    return ', ' + dayofWeek
  } else {
    return dayofWeek
  }
}
