import React from 'react'
import homeIcon from '../../assets/svg/header-icon-home.svg'
import { useNavigate } from 'react-router'
import { Grid } from '@mui/material'
import FormDateField from '../../components/form-filed/FormDateField'
import FormSelect from '../../components/form-filed/FormSelect'
import { reportTypeIdNames } from './types'
import Button from '@mui/material/Button'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

interface IProps {}

const ActivityList: React.FC<IProps> = (props) => {
  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate('/home')
  }

  const handleChangeSelect = (event: any) => {}
  const handleChangeDate = (newValue: Date | null, name: string) => {}

  return (
    <>
      <div id="header-wrapper" className="landing-page-header">
        <div id="header">
          <div className="home-icon" onClick={() => navigate('/home')}>
            <img src={homeIcon} alt={'user-icon'} />
          </div>
          <div className="nav-icons">
            <ArrowBackIosIcon fontSize={'large'} style={{ marginRight: 20 }} onClick={() => handleBackClick()} />
          </div>
          <div className="nav-text activity-avatar">Activity</div>
          <div className="header-right">
            <div id="valido-logo" onClick={() => navigate('/home')}>
              <span>Valido</span>
            </div>
          </div>
        </div>
      </div>

      <div id="main-no-grid">
        <div className="col-header">
          <Grid container spacing={1} style={{ marginBottom: 16 }}>
            <Grid item xs={12} md={12}>
              <div className="text-heading-pink">Select Report</div>
            </Grid>
            <Grid item xs={3} md={3}>
              <FormDateField
                name="startDate"
                label="Start Date DD/MM/YY"
                handleChange={handleChangeDate}
                format={'dd/MM/yy'}
              />
            </Grid>

            <Grid item xs={3} md={3}>
              <FormDateField
                name="endDate"
                label="End Date DD/MM/YY"
                handleChange={handleChangeDate}
                format={'dd/MM/yyyy'}
              />
            </Grid>
            <Grid item xs={6} md={6} style={{ display: 'flex' }}>
              <FormSelect
                label="Report Type"
                name="reportType"
                onChange={handleChangeSelect}
                hideNone
                data={reportTypeIdNames}
              />
              <Button
                data-cy="Go"
                id="Go"
                size={'small'}
                color={'primary'}
                variant={'contained'}
                style={{ height: 54, marginLeft: 8, marginTop: 7 }}
              >
                Go
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

export default ActivityList
