import React, { useEffect } from 'react'
import homeIcon from '../../assets/svg/header-icon-home.svg'
import { useAppDispatch, useAppSelector } from '../../store'
import { shallowEqual, useSelector } from 'react-redux'
import { selectProviderId } from '../../store/reducers/auth/types'
import { useNavigate } from 'react-router'
import usePagination from '../../hook/usePagination'
import Loading from '../../components/ui/Loading'
import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material'
import CustomTableHead from '../../components/table/CustomTableHead'
import { fetchFacilities } from './thunk'
import { facilityListColumns } from './types'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import StatusCell from '../../components/ui/StatusCell'
import FormTextField from '../../components/form-filed/FormTextField'
import FormSelect from '../../components/form-filed/FormSelect'
import { lifeCycleIdNames } from '../../store/reducers/common/types'
import { selectFacilities, selectFacilitiesLoading } from './reducers'

interface IProps {}

const FacilityList: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch()
  const providerId = useSelector(selectProviderId)
  const navigate = useNavigate()

  const [sortDirection, sortBy, handleRequestSort] = usePagination()

  const facilities = useAppSelector(selectFacilities, shallowEqual)
  const loading = useAppSelector(selectFacilitiesLoading, shallowEqual)

  useEffect(() => {
    if (providerId) {
      dispatch(fetchFacilities(providerId))
    }
  }, [dispatch, providerId])

  const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    navigate('' + event.currentTarget.dataset.id)
  }

  const handleBackClick = () => {
    navigate('/home')
  }

  const onChangeField = (event: any) => {}
  const handleChangeSelect = (event: any) => {}

  return (
    <div id="wrapper" className="stations-screen">
      {loading && <Loading />}
      <div id="header-wrapper" className="landing-page-header">
        <div id="header">
          <div className="home-icon" onClick={() => navigate('/home')}>
            <img src={homeIcon} alt={'user-icon'} />
          </div>
          <div className="nav-icons">
            <ArrowBackIosIcon fontSize={'large'} style={{ marginRight: 20 }} onClick={() => handleBackClick()} />
          </div>
          <div className="nav-text facility-avatar">Facilities</div>
          <div className="header-right">
            <div id="valido-logo" onClick={() => navigate('/home')}>
              <span>Valido</span>
            </div>
          </div>
        </div>
      </div>

      <div id="main-no-grid">
        <div className="col-header">
          <Grid container spacing={1} style={{ marginBottom: 16 }}>
            <Grid item xs={10} md={10}>
              <div className="text-heading-pink">Facilities</div>
            </Grid>

            <Grid
              item
              xs={2}
              md={2}
              style={{ textAlign: 'right', paddingRight: 6 }}
              onClick={() => navigate('/facilities/new')}
            >
              <div className="text-heading-blue" style={{ cursor: 'default' }}>
                + Facility
              </div>
            </Grid>

            <Grid item xs={2} md={2}>
              <FormTextField name="code" label="Sys Code" onChange={onChangeField} />
            </Grid>

            <Grid item xs={2} md={2}>
              <FormTextField name="clientRef" label="Client Ref" onChange={onChangeField} />
            </Grid>

            <Grid item xs={4} md={4}>
              <FormTextField name="name" label="Name" onChange={onChangeField} />
            </Grid>

            <Grid item xs={2} md={2}>
              <FormTextField name="publicId" label="Public ID" onChange={onChangeField} />
            </Grid>

            <Grid item xs={2} md={2}>
              <FormSelect label="Status" name="status" onChange={handleChangeSelect} data={lifeCycleIdNames} />
            </Grid>
          </Grid>

          <Table aria-label="tableTitle" size="medium">
            <CustomTableHead
              onRequestSort={handleRequestSort}
              sortDirection={sortDirection}
              sortBy={sortBy}
              columns={facilityListColumns}
            />
            <TableBody>
              {facilities && facilities.length > 0 ? (
                facilities.map((row) => (
                  <TableRow key={row.id} data-id={row.id} tabIndex={0} role="button" hover onClick={handleRowClick}>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.clientRef}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.publicId}</TableCell>
                    <TableCell>
                      <StatusCell status={row.lifecycle ? row.lifecycle.toString() : ''} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>No Record found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default FacilityList
