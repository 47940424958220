import React, { useEffect, useState } from 'react'
import homeIcon from '../../assets/svg/header-icon-home.svg'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store'
import { shallowEqual, useSelector } from 'react-redux'
import { selectProviderId } from '../../store/reducers/auth/types'
import { auStates, Partner } from './types'
import { Form } from 'react-final-form'
import { Grid } from '@mui/material'
import { TextField } from '../../components/final-form/TextField'
import { Lifecycle, LifecycleOnly } from '../../store/reducers/common/types'
import { useNavigate } from 'react-router'
import FormEditToolBar from '../../components/nav/FormEditToolBar'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Select } from '../../components/final-form/Select'
import { selectPartner, selectPartnerLoading } from './reducers'
import Loading from '../../components/ui/Loading'
import { fetchPartnerById } from './thunk'

interface IProps {}

const PartnerForm: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch()
  const providerId = useSelector(selectProviderId)
  const navigate = useNavigate()

  const [initialValue, setInitialValue] = useState<Partner>()

  let { partnerId } = useParams()

  const partner = useAppSelector(selectPartner, shallowEqual)
  const loading = useAppSelector(selectPartnerLoading, shallowEqual)

  useEffect(() => {
    if (providerId && partnerId) {
      dispatch(fetchPartnerById({ providerId, partnerId }))
    }
  }, [dispatch, providerId, partnerId])

  useEffect(() => {
    if (partner) {
      setInitialValue(partner)
    }
  }, [partner])

  const onSubmit = (values: Partner) => {
    console.log(values)
  }

  const onUpdateLifeCycle = (lifeCycle: Lifecycle) => {
    let lifecycleOnly: LifecycleOnly = { lifecycle: lifeCycle }
    console.log(lifecycleOnly)
  }

  const onClickCancel = () => {
    navigate('/partners')
  }

  const handleBackClick = () => {
    navigate('/partners')
  }

  return (
    <div id="wrapper" className="partners-screen">
      {loading && <Loading />}
      <div id="header-wrapper" className="landing-page-header">
        <div id="header">
          <div className="home-icon" onClick={() => navigate('/home')}>
            <img src={homeIcon} alt={'user-icon'} />
          </div>
          <div className="nav-icons">
            <ArrowBackIosIcon fontSize={'large'} style={{ marginRight: 20 }} onClick={() => handleBackClick()} />
          </div>
          <div className="nav-text partner-avatar">Partner</div>
          <div className="header-right">
            <div id="valido-logo" onClick={() => navigate('/home')}>
              <span>Valido</span>
            </div>
          </div>
        </div>
      </div>

      <Form<Partner>
        onSubmit={onSubmit}
        initialValues={initialValue}
        render={({ handleSubmit, form, initialValues, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit} id="editForm" noValidate>
              <div id="main-no-grid">
                <div className="col-header">
                  <div className="text-heading-pink" style={{ paddingBottom: 8 }}>
                    Partner
                  </div>
                  <Grid container spacing={1}>
                    <Grid item xs={2} md={2}>
                      <TextField name="code" label="Sys Code" variant="outlined" type="text" disabled id="code" />
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <TextField name="clientRef" label="Client Ref" variant="outlined" required={true} type="text" />
                    </Grid>
                    <Grid item xs={5} md={5}>
                      <TextField name="name" label="Business Name" variant="outlined" required={true} type="text" />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextField name="abn" label="ABN" variant="outlined" required={true} type="text" />
                    </Grid>

                    <Grid item xs={4} md={4}>
                      <TextField
                        name="addressLine1"
                        label="Address Line1"
                        variant="outlined"
                        required={true}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextField
                        name="addressLine2"
                        label="Address Line2"
                        variant="outlined"
                        required={true}
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={2} md={2}>
                      <TextField name="suburb" label="Suburb" variant="outlined" required={true} type="text" />
                    </Grid>

                    <Grid item xs={2} md={2}>
                      <Select
                        label="State"
                        name="state"
                        variant="outlined"
                        required={true}
                        data={auStates}
                        id="userRole"
                      />
                    </Grid>

                    <Grid item xs={1} md={1}>
                      <TextField name="postCode" label="Postcode" variant="outlined" required={true} type="text" />
                    </Grid>

                    <Grid item xs={4} md={4}>
                      <TextField
                        name="businessContactName"
                        label="Business Contact Name"
                        variant="outlined"
                        required={true}
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={5} md={5}>
                      <TextField
                        name="businessContactEmail"
                        label="Email"
                        variant="outlined"
                        required={true}
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={3} md={3}>
                      <TextField
                        name="businessContactPhone"
                        label="Phone"
                        variant="outlined"
                        required={true}
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={4} md={4}>
                      <TextField
                        name="directorContactName"
                        label="Director Contact Name"
                        variant="outlined"
                        required={true}
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={5} md={5}>
                      <TextField
                        name="directorContactEmail"
                        label="Email"
                        variant="outlined"
                        required={true}
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={3} md={3}>
                      <TextField
                        name="directorContactPhone"
                        label="Phone"
                        variant="outlined"
                        required={true}
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={7} md={7}>
                      <TextField name="bankName" label="Bank" variant="outlined" required={true} type="text" />
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <TextField name="bsbNo" label="BSB" variant="outlined" required={true} type="text" />
                    </Grid>

                    <Grid item xs={3} md={3}>
                      <TextField name="accountNo" label="Account No" variant="outlined" required={true} type="text" />
                    </Grid>
                  </Grid>
                </div>
              </div>
              <FormEditToolBar
                onUpdateLifeCycle={onUpdateLifeCycle}
                onClickCancel={onClickCancel}
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
                defaultLifeCycle={Lifecycle.Active}
              />
            </form>
          )
        }}
      />
    </div>
  )
}

export default PartnerForm
