import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './pages/auth/login/login'
import PublicLayout from './layout/public-layout'
import PrivateLayout from './layout/private-layout'
import Home from './pages/home'
import Activities from './pages/activities/activity-list'
import UserList from './pages/users/user-list'
import PartnerList from './pages/partners/partner-list'
import PartnerForm from './pages/partners/partner-form'
import UserForm from './pages/users/user-form'
import FacilityList from './pages/facilities/facility-list'
import FacilityForm from './pages/facilities/facility-form'
import OfferList from './pages/offers/offer-list'
import OfferForm from './pages/offers/offer-form'
import PartnerInviteForm from './pages/partners/partner-invite-form'

interface IProps {}

const RoutesUtil: React.FC<IProps> = (props) => {
  return (
    <>
      <Router>
        <div>
          <Routes>
            <Route path="/users" element={<PrivateLayout />}>
              <Route index={true} element={<UserList />} />
              <Route index={false} path=":userId" element={<UserForm />} />
              <Route index={false} path="new" element={<UserForm />} />
            </Route>

            <Route path="/partners" element={<PrivateLayout />}>
              <Route index={true} element={<PartnerList />} />
              <Route index={false} path=":partnerId" element={<PartnerForm />} />
              <Route index={false} path="new" element={<PartnerForm />} />
              <Route index={false} path="invite" element={<PartnerInviteForm />} />
            </Route>

            <Route path="/facilities" element={<PrivateLayout />}>
              <Route index={true} element={<FacilityList />} />
              <Route index={false} path=":facilityId" element={<FacilityForm />} />
              <Route index={false} path="new" element={<FacilityForm />} />
            </Route>

            <Route path="/offers" element={<PrivateLayout />}>
              <Route index={true} element={<OfferList />} />
              <Route index={false} path=":offerId" element={<OfferForm />} />
              <Route index={false} path="new" element={<OfferForm />} />
            </Route>

            <Route path="/login" element={<PublicLayout />}>
              <Route index element={<Login />} />
            </Route>

            <Route path="/activities" element={<PrivateLayout />}>
              <Route index element={<Activities />} />
            </Route>

            <Route path="/home" element={<PrivateLayout />}>
              <Route index element={<Home />} />
            </Route>

            <Route path="/" element={<PublicLayout />}>
              <Route index element={<Login />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </>
  )
}

export default RoutesUtil
