import { StaffState } from './types'
import { createSlice } from '@reduxjs/toolkit'

const initialState: StaffState = {
  loading: false,
  success: false,
  error: null,
  staffs: null,
}

const staffsSlice = createSlice({
  name: 'staffsReducer',
  initialState,
  reducers: {
    getStaffsRequest: (state) => {
      state.loading = true
      state.error = null
    },
    getStaffsSuccess: (state, action) => {
      state.loading = false
      state.staffs = action.payload
    },
    getStaffError: (state, action) => {
      state.loading = false
      state.error = action.payload.error
    },
    getStaffsReset: (state) => {
      state = initialState
    },
  },
})

export const { getStaffsRequest, getStaffsSuccess, getStaffError, getStaffsReset } = staffsSlice.actions

export default staffsSlice.reducer
