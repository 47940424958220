import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormGroup,
  FormGroupProps,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  Grid,
} from '@mui/material'
import React from 'react'
import { ReactComponent as OnStreetIcon } from '../../assets/svg/on-street.svg'
import { ErrorMessage, ShowErrorFunc, showErrorOnChange, useFieldForErrors } from './Util'
import { Field, FieldProps } from 'react-final-form'

export interface CheckboxData {
  label: string
  value: unknown
  disabled?: boolean
  indeterminate?: boolean
}

export interface CheckboxesProps extends Partial<Omit<MuiCheckboxProps, 'onChange'>> {
  name: string
  data: CheckboxData | CheckboxData[]
  label?: string
  required?: boolean
  helperText?: string
  fieldProps?: Partial<FieldProps<any, any>>
  formControlProps?: Partial<FormControlProps>
  formGroupProps?: Partial<FormGroupProps>
  formLabelProps?: Partial<FormLabelProps>
  formControlLabelProps?: Partial<FormControlLabelProps>
  formHelperTextProps?: Partial<FormHelperTextProps>
  showError?: ShowErrorFunc
}

export function CheckboxesFacility(props: CheckboxesProps) {
  const {
    required,
    label,
    data,
    name,
    helperText,
    fieldProps,
    formControlProps,
    formGroupProps,
    formLabelProps,
    formControlLabelProps,
    formHelperTextProps,
    showError = showErrorOnChange,
    ...restCheckboxes
  } = props

  const itemsData = Array.isArray(data) ? data : [data]
  const single = !Array.isArray(data)
  const field = useFieldForErrors(name)
  const isError = showError(field)

  return (
    <Grid container spacing={1}>
      <FormControl required={required} error={isError} {...formControlProps}>
        {label ? <FormLabel {...formLabelProps}>{label}</FormLabel> : <></>}
        <FormGroup {...formGroupProps} style={{ flexDirection: 'row', paddingLeft: 4 }}>
          {itemsData.map((item: CheckboxData, idx: number) => (
            <Grid item xs={3} md={3} minWidth={200}>
              <FormControlLabel
                key={idx}
                name={name}
                label={item.label}
                value={single ? undefined : item.value}
                disabled={item.disabled}
                control={
                  <Field
                    type="checkbox"
                    name={name}
                    render={({ input: { name, value, onChange, checked, ...restInput } }) => (
                      <MuiCheckbox
                        name={name}
                        value={value}
                        onChange={onChange}
                        checked={checked}
                        disabled={item.disabled}
                        inputProps={{ required, ...restInput }}
                        indeterminate={item.indeterminate}
                        {...restCheckboxes}
                      />
                    )}
                    {...fieldProps}
                  />
                }
                {...formControlLabelProps}
              />
              {idx === 0 && (
                <div style={{ display: 'none' }}>
                  <OnStreetIcon style={{ height: 25 }} />
                </div>
              )}
            </Grid>
          ))}
        </FormGroup>
        <ErrorMessage
          showError={isError}
          meta={field.meta}
          formHelperTextProps={formHelperTextProps}
          helperText={helperText}
        />
      </FormControl>
    </Grid>
  )
}
