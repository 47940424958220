import * as React from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material'

const formStyle = makeStyles((theme: Theme) =>
  createStyles({
    middleWithPaddingTrue: {
      marginTop: 30,
      paddingLeft: '0px !important',
      color: '#57595C',
      fontSize: '17px',
      fontWeight: 600,
    },
    middleWithPaddingLessTrue: {
      marginTop: 30,
      paddingLeft: '0px !important',
      color: '#57595C',
      fontSize: '17px',
      fontWeight: 600,
    },
  }),
)

interface IProps {
  id: string
  label: string
  lessPadding?: boolean
}

const FormLabel: React.FC<IProps> = ({ id, label, lessPadding }) => {
  const classes = formStyle()
  return (
    <>
      <div id={id} className={lessPadding ? classes.middleWithPaddingLessTrue : classes.middleWithPaddingTrue}>
        {label}
      </div>
    </>
  )
}
export default FormLabel
