import React, { useEffect } from 'react'
import homeIcon from '../../assets/svg/header-icon-home.svg'
import Loading from '../../components/ui/Loading'
import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material'
import CustomTableHead from '../../components/table/CustomTableHead'
import { useAppDispatch, useAppSelector } from '../../store'
import { shallowEqual, useSelector } from 'react-redux'
import { selectProviderId } from '../../store/reducers/auth/types'
import { useNavigate } from 'react-router'
import usePagination from '../../hook/usePagination'
import { fetchPartners } from './thunk'
import { partnerListColumns } from './types'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import FormTextField from '../../components/form-filed/FormTextField'
import FormSelect from '../../components/form-filed/FormSelect'
import { lifeCycleIdNames } from '../../store/reducers/common/types'
import InvitationStatusCell from '../../components/ui/InvitationStatusCell'
import { selectPartners, selectPartnersLoading } from './reducers'

interface IProps {}

const PartnerList: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch()
  const providerId = useSelector(selectProviderId)
  const navigate = useNavigate()

  const [sortDirection, sortBy, handleRequestSort] = usePagination()

  const partners = useAppSelector(selectPartners, shallowEqual)
  const loading = useAppSelector(selectPartnersLoading, shallowEqual)

  useEffect(() => {
    if (providerId) {
      dispatch(fetchPartners(providerId))
    }
  }, [dispatch, providerId])

  const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    navigate('' + event.currentTarget.dataset.id)
  }

  const handleBackClick = () => {
    navigate('/home')
  }

  const onChangeField = (event: any) => {}
  const handleChangeSelect = (event: any) => {}

  return (
    <div id="wrapper" className="partners-screen">
      {loading && <Loading />}
      <div id="header-wrapper" className="landing-page-header">
        <div id="header">
          <div className="home-icon" onClick={() => navigate('/home')}>
            <img src={homeIcon} alt={'user-icon'} />
          </div>
          <div className="nav-icons">
            <ArrowBackIosIcon fontSize={'large'} style={{ marginRight: 20 }} onClick={() => handleBackClick()} />
          </div>
          <div className="nav-text  partner-avatar">Partner</div>
          <div className="header-right">
            <div id="valido-logo" onClick={() => navigate('/home')}>
              <span>Valido</span>
            </div>
          </div>
        </div>
      </div>

      <div id="main-no-grid">
        <div className="col-header">
          <Grid container spacing={1} style={{ marginBottom: 16 }}>
            <Grid item xs={10} md={10}>
              <div className="text-heading-pink">Partners</div>
            </Grid>

            <Grid
              item
              xs={2}
              md={2}
              style={{ textAlign: 'right', paddingRight: 6 }}
              onClick={() => navigate('/partners/invite')}
            >
              <div className="text-heading-blue">+ Partner</div>
            </Grid>

            <Grid item xs={2} md={2}>
              <FormTextField name="clientRef" label="Client Ref" onChange={onChangeField} />
            </Grid>

            <Grid item xs={4} md={4}>
              <FormTextField name="name" label="Business Name" onChange={onChangeField} />
            </Grid>

            <Grid item xs={4} md={4}>
              <FormTextField name="address" label="Address" onChange={onChangeField} />
            </Grid>

            <Grid item xs={2} md={2}>
              <FormSelect label="Status" name="status" onChange={handleChangeSelect} data={lifeCycleIdNames} />
            </Grid>
          </Grid>

          <Table aria-label="tableTitle" size="medium">
            <CustomTableHead
              onRequestSort={handleRequestSort}
              sortDirection={sortDirection}
              sortBy={sortBy}
              columns={partnerListColumns}
            />
            <TableBody>
              {partners && partners.length > 0 ? (
                partners.map((row) => (
                  <TableRow key={row.uuid} data-id={row.uuid} tabIndex={0} role="button" hover onClick={handleRowClick}>
                    <TableCell>{row.clientRef}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>
                      <InvitationStatusCell status={row.invitationStatus ? row.invitationStatus.toString() : ''} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No Record found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default PartnerList
