import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Button, Toolbar } from '@mui/material'
import { Lifecycle } from '../../store/reducers/common/types'

const useStyles = makeStyles((theme) => ({
  toolBar: {
    textAlign: 'right',
    bottom: 67,
    marginRight: 36,
    display: 'flow-root',
    minHeight: 60,
    height: 70,
  },
  containedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold!important',
    textTransform: 'none',
    fontSize: '1rem !important',
    borderRadius: '8px',
    letterSpacing: '0',
    color: '#ffffff!important',
    height: '50px',
    borderColor: '#147AC6!important',
    backgroundColor: '#147AC6!important',
    width: 180,
    marginLeft: '10px!important',
  },
  outlinedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold!important',
    textTransform: 'none',
    fontSize: '1rem !important',
    borderRadius: '8px',
    letterSpacing: '0',
    color: '#147AC6 !important',
    height: '50px',
    borderColor: '#147AC6!important',
    width: 180,
    marginLeft: '10px!important',
  },
}))

interface IProps {
  defaultLifeCycle?: Lifecycle
  onUpdateLifeCycle: (lifeCycle: Lifecycle) => void
  onClickCancel: () => void
  submitting?: boolean
  pristine?: boolean
  handleSubmit: () => void
  displayInvite?: boolean
}

const FormEditToolBar: React.FC<IProps> = (props) => {
  const { onClickCancel, submitting, pristine, displayInvite, handleSubmit } = props
  const classes = useStyles()

  return (
    <Toolbar disableGutters className={classes.toolBar}>
      {/*<LifecycleSelect
        name="lifecycle"
        defaultValue={defaultLifeCycle}
        disabled={false}
        onUpdateLifeCycle={onUpdateLifeCycle}
      />*/}
      <Button
        data-cy="Cancel"
        id="Cancel"
        className={classes.outlinedButton}
        variant="outlined"
        onClick={() => onClickCancel()}
      >
        Cancel
      </Button>
      <Button
        data-cy="Save"
        id="save"
        className={classes.containedButton}
        disabled={submitting || pristine}
        variant="contained"
        onClick={handleSubmit}
      >
        {displayInvite ? 'Invite' : 'Save'}
      </Button>
      <Button
        data-cy="Done"
        id="Done"
        className={classes.containedButton}
        variant="contained"
        onClick={() => onClickCancel()}
      >
        Done
      </Button>
    </Toolbar>
  )
}

export default FormEditToolBar
