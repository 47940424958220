import React, { useState } from 'react'
import homeIcon from '../../assets/svg/header-icon-home.svg'
import { Partner } from './types'
import { Form } from 'react-final-form'
import { Grid } from '@mui/material'
import { TextField } from '../../components/final-form/TextField'
import { Lifecycle, LifecycleOnly } from '../../store/reducers/common/types'
import { useNavigate } from 'react-router'
import FormEditToolBar from '../../components/nav/FormEditToolBar'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

interface IProps {}

const PartnerInviteForm: React.FC<IProps> = (props) => {
  const navigate = useNavigate()

  const [initialValue, setInitialValue] = useState<Partner>()

  const onSubmit = (values: Partner) => {
    console.log(values)
    setInitialValue(values)
  }

  const onUpdateLifeCycle = (lifeCycle: Lifecycle) => {
    let lifecycleOnly: LifecycleOnly = { lifecycle: lifeCycle }
    console.log(lifecycleOnly)
  }

  const onClickCancel = () => {
    navigate('/partners')
  }

  const handleBackClick = () => {
    navigate('/partners')
  }

  return (
    <div id="wrapper" className="partners-screen">
      <div id="header-wrapper" className="landing-page-header">
        <div id="header">
          <div className="home-icon" onClick={() => navigate('/home')}>
            <img src={homeIcon} alt={'user-icon'} />
          </div>
          <div className="nav-icons">
            <ArrowBackIosIcon fontSize={'large'} style={{ marginRight: 20 }} onClick={() => handleBackClick()} />
          </div>
          <div className="nav-text partner-avatar">Partner</div>
          <div className="header-right">
            <div id="valido-logo" onClick={() => navigate('/home')}>
              <span>Valido</span>
            </div>
          </div>
        </div>
      </div>

      <Form<Partner>
        onSubmit={onSubmit}
        initialValues={initialValue}
        render={({ handleSubmit, form, initialValues, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit} id="editForm" noValidate>
              <div id="main-no-grid">
                <div className="col-header">
                  <h3 className="text-pink">Invite Partner</h3>
                  <Grid container spacing={1}>
                    <Grid item xs={2} md={2}>
                      <TextField name="clientRef" label="Client Ref" variant="outlined" required={true} type="text" />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextField name="name" label="Business Name" variant="outlined" required={true} type="text" />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextField
                        name="businessContactName"
                        label="Business Contact"
                        variant="outlined"
                        required={true}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <TextField
                        name="businessContactEmail"
                        label="Email"
                        variant="outlined"
                        required={true}
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={5} md={5}>
                      <TextField
                        name="senderContactName"
                        label="Sender Name"
                        variant="outlined"
                        required={true}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextField
                        name="senderCPhone"
                        label="Sender Phone"
                        variant="outlined"
                        required={true}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <TextField
                        name="senderEmail"
                        label="Sender Email"
                        variant="outlined"
                        required={true}
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
              <FormEditToolBar
                onUpdateLifeCycle={onUpdateLifeCycle}
                onClickCancel={onClickCancel}
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
                defaultLifeCycle={Lifecycle.Active}
                displayInvite
              />
            </form>
          )
        }}
      />
    </div>
  )
}

export default PartnerInviteForm
