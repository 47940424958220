import axios from 'axios'
import { PROVIDER_PARTNER_API_URL } from './types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchPartners = createAsyncThunk(
  'partners/fetchPartners',
  async (providerId: string, { getState, requestId }) => {
    // @ts-ignore
    const { currentRequestId, loading } = getState().partnerReducer
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const asyncResult = await axios.get('/reward-service' + PROVIDER_PARTNER_API_URL.replace(':providerId', providerId))
    return asyncResult.data
  },
)

export const fetchPartnerById = createAsyncThunk(
  'partners/fetchPartnerById',
  async ({ providerId, partnerId }: { providerId: string; partnerId: string }, { getState, requestId }) => {
    // @ts-ignore
    const { currentRequestId, loading } = getState().partnerReducer
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const asyncResult = await axios.get(
      '/reward-service' + PROVIDER_PARTNER_API_URL.replace(':providerId', providerId) + '/' + partnerId,
    )
    return asyncResult.data
  },
)
