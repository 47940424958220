import { IdName, InvitationStatus } from '../../store/reducers/common/types'
import { TableColumn } from '../../components/table/types'

export interface Partner {
  uuid: string
  code: string
  clientRef: string
  name: string
  invitationStatus: InvitationStatus
  abn: string
  line1: string
  line2: string | null
  postcode: string
  suburb: string
  state: string
  countryIso: string
  address: string
  version: number
}

export interface PartnerState {
  success: boolean
  error: string | null
  partners: Partner[] | null
  partner: Partner | null
  currentRequestId: string | undefined
  loading: 'idle' | 'pending'
}

export interface Address {
  line1: string
  line2: string | null
  postcode: string
  suburb: string
  state: string
  countryIso: string //  eg  AU
}

export const auStates: IdName[] = [
  {
    name: 'SA',
    id: 'SA',
  },
  {
    name: 'VIC',
    id: 'VIC',
  },
  {
    name: 'NSW',
    id: 'NSW',
  },
  {
    name: 'WA',
    id: 'WA',
  },
  {
    name: 'TAS',
    id: 'TAS',
  },
  {
    name: 'NT',
    id: 'NT',
  },
  {
    name: 'ACT',
    id: 'ACT',
  },
  {
    name: 'QLD',
    id: 'QLD',
  },
]

export const PROVIDER_PARTNER_API_URL = '/partners'

export const partnerListColumns: TableColumn[] = [
  {
    id: 'clientRef',
    label: 'Client Ref',
    name: 'clientRef',
    sortable: false,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'businessName',
    label: 'Business Name',
    name: 'businessName',
    sortable: false,
    filterable: false,
    minWidth: 400 / 12,
  },
  {
    id: 'address',
    label: 'Address',
    name: 'address',
    sortable: false,
    filterable: false,
    minWidth: 400 / 12,
  },
  {
    id: 'status',
    label: 'Status',
    name: 'lifecycle',
    sortable: false,
    filterable: false,
    minWidth: 200 / 12,
  },
]
