import { Lifecycle } from '../../store/reducers/common/types'
import { TableColumn } from '../../components/table/types'

export interface Offer {
  id: string
  code: string
  clientRef: string
  name: string
  lifecycle: Lifecycle
  startDate: string
  endDate: string
  startTime: number
  endTime: number
  validMonday: boolean
  validTuesday: boolean
  validWednesday: boolean
  validThursday: boolean
  validFriday: boolean
  validSaturday: boolean
  validSunday: boolean
  timeValueMinutes: number
  timeValueAmount: number
  noOfFacilities: number
  networkProviderId: string
  version: number
  weekDaysSelected: boolean
  allDaySelected: boolean
  weekEndSelected: boolean
}

export interface OfferState {
  success: boolean
  error: string | null
  offers: Offer[] | null
  offer: Offer | null
  loading: 'idle' | 'pending'
  currentRequestId: string | undefined
}

export const PROVIDER_OFFER_API_URL = '/network-providers/:providerId/offers'

export const offerListColumns: TableColumn[] = [
  /*  {
    id: 'sysCode',
    label: 'Sys Code',
    name: 'code',
    sortable: false,
    filterable: false,
    minWidth: 100 / 12,
  },
  {
    id: 'clientRef',
    label: 'Client Ref',
    name: 'clientRef',
    sortable: false,
    filterable: false,
    minWidth: 100 / 12,
  },*/
  {
    id: 'name',
    label: 'Name',
    name: 'Name',
    sortable: false,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'noOfFacilities',
    label: 'Facilities',
    name: 'Name',
    sortable: false,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'startDate',
    label: 'Starts',
    name: 'startDate',
    sortable: false,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'endDate',
    label: 'Ends',
    name: 'endDate',
    sortable: false,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'timeValueMinutes',
    label: 'Time Value',
    name: 'Name',
    sortable: false,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'status',
    label: 'Status',
    name: 'lifecycle',
    sortable: false,
    filterable: false,
    minWidth: 200 / 12,
  },
]
