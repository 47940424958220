import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import { RootState } from '../../index'

export interface AuthState {
  initialized: boolean
  loggedIn: boolean
  loading: boolean
  user: firebase.User | null
  error: string | null
}

export const selectCurrentFirebaseUser = (state: RootState) =>
  state.authReducer.loggedIn ? state.authReducer.user : null
export const selectIsLoggedOn = (state: RootState) => [state.authReducer.initialized, state.authReducer.loggedIn]
export const isCompletedLogin = (state: RootState) =>
  state.authReducer.loggedIn && state.authReducer.user ? true : false
export const selectAuthError = (state: RootState) => state.authReducer.error
export const selectCurrentFirebaseToken = async (state: RootState) =>
  state.authReducer.loggedIn ? await state.authReducer.user?.getIdToken : null

export const selectProviderId = (state: RootState) => {
  if (state.authReducer.loggedIn && state.authReducer.user) {
    if (
      state.authReducer.user.uid === 'VYvzZZpVuATdeUCimDprDhU4ra63' ||
      state.authReducer.user.uid === 'b9ba3baf-1f31-4c0a-801b-62e409cb013b'
    ) {
      return 'f7689b9e-b194-4736-85ca-51cd3633c21a'
    } else if (
      state.authReducer.user.uid === '7597b069-2bfe-4cd0-b37e-0705d13493ae' ||
      state.authReducer.user.uid === '0d07f3c6-aa98-4c60-9915-6a3bd989b426' ||
      state.authReducer.user.uid === 'c8b8db6f-66b9-47e4-9faa-1c1414a41346'
    ) {
      return 'f7689b9e-b194-4736-85ca-51cd3633c21a'
    } else {
      return 'f7689b9e-b194-4736-85ca-51cd3633c21a'
    }
  }
}
