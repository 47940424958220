import { FacilityState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { fetchFacilities, fetchFacilityById } from './thunk'
import { RootState } from '../../store'

const initialState: FacilityState = {
  success: false,
  error: null,
  facilities: null,
  facility: null,
  loading: 'idle',
  currentRequestId: undefined,
}

const facilitySlice = createSlice({
  name: 'facilityReducer',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFacilities.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchFacilities.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.facilities = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchFacilities.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
    [fetchFacilityById.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchFacilityById.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.facility = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchFacilityById.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
  },
})

export const selectFacilities = (state: RootState) => state.facilityReducer.facilities
export const selectFacilitiesLoading = (state: RootState) => state.facilityReducer.loading === 'pending'
export const selectFacility = (state: RootState) => state.facilityReducer.facility
export const selectFacilityLoading = (state: RootState) => state.facilityReducer.loading === 'pending'

export default facilitySlice.reducer
