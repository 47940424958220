import { IdName, Lifecycle } from '../../store/reducers/common/types'
import { TableColumn } from '../../components/table/types'

export interface Facility {
  uuid: string
  code: string
  clientRef: string
  name: string
  publicId: string
  lifecycle: Lifecycle
  latitude: string
  longitude: string
  mapLabel: string
  timeZoneId: string
  networkProviderId: string
  version: number
  id: string
}

export interface FacilityState {
  success: boolean
  error: string | null
  facilities: Facility[] | null
  facility: Facility | null
  currentRequestId: string | undefined
  loading: 'idle' | 'pending'
}

export const PARTNER_FACILITY_API_URL = '/network-providers/:providerId/facilities'

export const facilityListColumns: TableColumn[] = [
  {
    id: 'sysCode',
    label: 'Sys Code',
    name: 'code',
    sortable: false,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'clientRef',
    label: 'Client Ref',
    name: 'clientRef',
    sortable: false,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'name',
    label: 'Name',
    name: 'Name',
    sortable: false,
    filterable: false,
    minWidth: 400 / 12,
  },
  {
    id: 'publicId',
    label: 'Public ID',
    name: 'Name',
    sortable: false,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'status',
    label: 'Status',
    name: 'lifecycle',
    sortable: false,
    filterable: false,
    minWidth: 200 / 12,
  },
]

export const systems: IdName[] = [
  {
    name: 'Gated',
    id: 'Gated',
  },
  {
    name: 'Enforced',
    id: 'Enforced',
  },
  {
    name: 'Attended',
    id: 'Attended',
  },
]

export const makes: IdName[] = [
  {
    name: 'Skidata',
    id: 'Skidata',
  },
  {
    name: 'Designa',
    id: 'Designa',
  },
  {
    name: 'S & B',
    id: 'S&B',
  },
  {
    name: 'Nedap',
    id: 'Nedap',
  },
]

export const models: IdName[] = [
  {
    name: 'alpha',
    id: 'alpha',
  },
  {
    name: 'V1',
    id: 'V1',
  },
  {
    name: 'V2',
    id: 'V2',
  },
]

export const versions: IdName[] = [
  {
    name: 'V1',
    id: 'V1',
  },
  {
    name: 'V2',
    id: 'V2',
  },
]
