import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      light: 'CDB3DC',
      main: '#147AC6',
      dark: '#365F84',
    },
    secondary: {
      main: '#fd5a5f',
    },
    background: {
      default: '#ffffff',
    },
    action: {
      disabledBackground: '#f8d7d8',
      disabled: 'white',
    },
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '9px',
          color: '#ffffff',
          textTransform: 'none',
          fontSize: '1.1rem',
          fontFamily: 'Nunito Sans',
          fontWeight: '700',
          letterSpacing: '0',
          '&.Mui-disabled': {
            color: '#ffffff !important',
            backgroundColor: '#87BAE0 !important',
          },
          '&.MuiButton-outlined': {
            color: '#fd5a5f',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontFamily: 'Nunito Sans',
          color: '#3A5F80 !important',
          fontSize: '14px',
          fontWeight: 700,
          lineHeight: '1rem',
          backgroundColor: '#DFE7F0',
          letterSpacing: '-0.07px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontFamily: 'Nunito Sans',
          color: '#3A5F80 !important',
          fontSize: 14,
          fontWeight: 700,
          lineHeight: '1rem',
          backgroundColor: '#DFE7F0',
          letterSpacing: '-0.07px',
        },
        body: {
          fontFamily: 'Nunito Sans',
          color: '#363636',
          fontSize: 14,
          letterSpacing: -0.07,
          fontWeight: 'normal',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: '#FCFCFC',
          },
          '&$hover': {
            '&:hover': {
              backgroundColor: '#FCFCFC',
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#383838',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          color: '#B71FB7',
        },
        track: {
          color: '#674FB5',
        },
        rail: {
          color: '#674FB5',
        },
      },
    },
    /*MuiSelect: {
      styleOverrides: {
        select: {
          fontFamily: 'Nunito Sans',
          height: '12px !important',
          minHeight: '12px !important',
          fontSize: 14,
          '&:disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.38) !important',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: 'Nunito Sans !important',
          fontSize: 14,
        },
      },
    },*/
  },
})
