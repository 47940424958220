import React, { useEffect, useState } from 'react'
import homeIcon from '../../assets/svg/header-icon-home.svg'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store'
import { shallowEqual, useSelector } from 'react-redux'
import { selectProviderId } from '../../store/reducers/auth/types'
import { Offer } from './types'
import { Lifecycle, LifecycleOnly } from '../../store/reducers/common/types'
import { useNavigate } from 'react-router'
import { Grid, Slider } from '@mui/material'
import { TextField } from '../../components/final-form/TextField'
import FormEditToolBar from '../../components/nav/FormEditToolBar'
import { Form } from 'react-final-form'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { DatePicker } from '../../components/final-form/DatePicker'
import { CheckboxData, Checkboxes } from '../../components/final-form/Checkboxes'
import { TimePicker } from '../../components/final-form/TimePicker'
import { fetchFacilities } from '../facilities/thunk'
import { CheckboxesFacility } from '../../components/final-form/CheckboxesFacility'
import { selectFacilities } from '../facilities/reducers'
import { selectOffer, selectOfferLoading } from './reducers'
import { fetchOfferById } from './thunk'
import Loading from '../../components/ui/Loading'

interface IProps {}

//const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)'

const marks = [
  {
    value: 0,
    label: '0',
  },

  {
    value: 15,
    label: '15min',
  },
  {
    value: 30,
    label: '30min',
  },
  {
    value: 45,
    label: '45min',
  },
  {
    value: 60,
    label: '1hr',
  },
  {
    value: 120,
    label: '2hr',
  },
  {
    value: 240,
    label: '4hr',
  },
  {
    value: 1440,
    label: 'All Day',
  },
]

/*const IOSSlider = styled(Slider)(({ theme }) => ({
  color: '#ff00fc',
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    color: '#000000',
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}))*/

const OfferForm: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch()
  const providerId = useSelector(selectProviderId)
  const navigate = useNavigate()

  let { offerId } = useParams()

  const [initialValue, setInitialValue] = useState<Offer>()

  const [facilityIdNames, setFacilityIdNames] = useState<CheckboxData[]>([])
  const facilities = useAppSelector(selectFacilities, shallowEqual)

  const offer = useAppSelector(selectOffer, shallowEqual)
  const loading = useAppSelector(selectOfferLoading, shallowEqual)

  useEffect(() => {
    if (providerId && offerId) {
      dispatch(fetchOfferById({ providerId, offerId }))
    }
  }, [dispatch, providerId, offerId])

  useEffect(() => {
    if (providerId) {
      dispatch(fetchFacilities(providerId))
    }
  }, [dispatch, providerId])

  useEffect(() => {
    if (offer) {
      setInitialValue(offer)
    }
  }, [offer])

  useEffect(() => {
    let facilityIdNames: CheckboxData[] = []
    facilityIdNames.push({
      value: 'ddd',
      label: 'On Street',
    })
    if (facilities) {
      facilities.map((row) => {
        return facilityIdNames.push({
          value: row.id,
          label: row.name,
        })
      })
    }
    setFacilityIdNames(facilityIdNames)
  }, [facilities])

  const onSubmit = (values: Offer) => {
    console.log(values)
  }

  const onUpdateLifeCycle = (lifeCycle: Lifecycle) => {
    let lifecycleOnly: LifecycleOnly = { lifecycle: lifeCycle }
    console.log(lifecycleOnly)
  }

  const onClickCancel = () => {
    navigate('/offers')
  }

  const handleBackClick = () => {
    navigate('/offers')
  }
  return (
    <div id="wrapper" className="stations-screen">
      {loading && <Loading />}
      <div id="header-wrapper" className="landing-page-header">
        <div id="header">
          <div className="home-icon" onClick={() => navigate('/home')}>
            <img src={homeIcon} alt={'user-icon'} />
          </div>
          <div className="nav-icons">
            <ArrowBackIosIcon fontSize={'large'} style={{ marginRight: 20 }} onClick={() => handleBackClick()} />
          </div>
          <div className="nav-text offer-avatar">Offers</div>
          <div className="header-right">
            <div id="valido-logo" onClick={() => navigate('/home')}>
              <span>Valido</span>
            </div>
          </div>
        </div>
      </div>

      <Form<Offer>
        onSubmit={onSubmit}
        initialValues={initialValue}
        render={({ handleSubmit, form, initialValues, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit} id="editForm" noValidate>
              <div id="main-no-grid">
                <div className="col-header">
                  <div className="text-heading-pink" style={{ paddingBottom: 8 }}>
                    Offer
                  </div>
                  <Grid container spacing={1}>
                    <Grid item xs={2} md={2}>
                      <TextField name="code" label="Sys Code" variant="outlined" type="text" disabled id="code" />
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <TextField name="clientRef" label="Client Ref" variant="outlined" required={true} type="text" />
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <TextField name="name" label="Name" variant="outlined" required={true} type="text" />
                    </Grid>

                    <Grid item xs={2} md={2}>
                      <DatePicker name="startDate" format="dd/MM/yyyy" required={false} label="Start Date" />
                    </Grid>

                    <Grid item xs={2} md={2}>
                      <DatePicker name="endDate" format="dd/MM/yyyy" required={false} label="End Date" />
                    </Grid>

                    <Grid item xs={12} md={12} style={{ marginTop: 16 }}>
                      <span className="text-pink">Validity</span>
                    </Grid>
                    <Grid item xs={1} md={1}>
                      <Checkboxes
                        name="validMonday"
                        required={false}
                        data={{
                          label: 'Mon',
                          value: values.validMonday,
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} md={1}>
                      <Checkboxes
                        name="validTuesday"
                        required={false}
                        data={{
                          label: 'Tue',
                          value: values.validTuesday,
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} md={1}>
                      <Checkboxes
                        name="validWednesday"
                        required={false}
                        data={{
                          label: 'Wed',
                          value: values.validWednesday,
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} md={1}>
                      <Checkboxes
                        name="validThursday"
                        required={false}
                        data={{
                          label: 'Thu',
                          value: values.validThursday,
                        }}
                      />
                    </Grid>

                    <Grid item xs={1} md={1}>
                      <Checkboxes
                        name="validFriday"
                        required={false}
                        data={{
                          label: 'Fri',
                          value: values.validFriday,
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} md={1}>
                      <Checkboxes
                        name="validSaturday"
                        required={false}
                        data={{
                          label: 'Sat',
                          value: values.validSaturday,
                        }}
                      />
                    </Grid>

                    <Grid item xs={2} md={2}>
                      <Checkboxes
                        name="validSunday"
                        required={false}
                        data={{
                          label: 'Sun',
                          value: values.validSunday,
                        }}
                      />
                    </Grid>

                    <Grid item xs={2} md={2}>
                      <TimePicker label="Start Time" name="startTime" required={false} />
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <TimePicker label="End Time" name="endTime" required={false} />
                    </Grid>

                    <Grid item xs={12} md={12} style={{ marginTop: 16 }}>
                      <span className="text-pink"> Time Value</span>
                    </Grid>

                    <Grid item xs={10} md={10} style={{ marginTop: 18, paddingRight: 10 }}>
                      <Slider
                        defaultValue={15}
                        step={15}
                        /*valueLabelDisplay="on"*/
                        marks={marks}
                        max={240}
                        color={'primary'}
                      />
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <TextField
                        name="timeValueAmount"
                        label="Value $"
                        variant="outlined"
                        required={true}
                        type="text"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} style={{ marginTop: 24, marginBottom: 16 }}>
                      <span className="text-pink">Facilities</span>
                    </Grid>
                  </Grid>

                  <CheckboxesFacility name="facilityIds" required={false} data={facilityIdNames} />
                </div>
              </div>
              <FormEditToolBar
                onUpdateLifeCycle={onUpdateLifeCycle}
                onClickCancel={onClickCancel}
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
                defaultLifeCycle={initialValues?.lifecycle}
              />
            </form>
          )
        }}
      />
    </div>
  )
}

export default OfferForm
