import { createSlice } from '@reduxjs/toolkit'
import { PartnerStaffStationSignOnState } from './types'

const initialState: PartnerStaffStationSignOnState = {
  loading: false,
  success: false,
  error: null,
  partnerStaffStationSignOn: null,
  signOnSuccess: false,
  signOffSuccess: false,
}

const partnerStaffStationSignOnSlice = createSlice({
  name: 'partnerStaffStationSignOnReducer',
  initialState,
  reducers: {
    getPartnerStaffStationSignOnRequest: (state) => {
      state.loading = true
      state.error = null
    },
    getPartnerStaffStationSignOnSuccess: (state, action) => {
      state.loading = false
      state.partnerStaffStationSignOn = action.payload
    },
    getPartnerStaffStationSignOnError: (state, action) => {
      state.loading = false
      state.error = action.payload.error
    },
    postPartnerStaffStationSignOnRequest: (state) => {
      state.loading = true
      state.error = null
      state.signOnSuccess = false
    },
    postPartnerStaffStationSignOnResponse: (state) => {
      state.loading = true
      state.error = null
      state.signOnSuccess = true
    },
    postPartnerStaffStationSignOffRequest: (state) => {
      state.loading = true
      state.error = null
      state.signOffSuccess = false
    },
    postPartnerStaffStationSignOffResponse: (state) => {
      state.loading = true
      state.error = null
      state.signOffSuccess = true
    },
    getPartnerStaffStationSignOnReset: (state) => {
      state = initialState
    },
  },
})

export const {
  getPartnerStaffStationSignOnRequest,
  getPartnerStaffStationSignOnSuccess,
  getPartnerStaffStationSignOnError,
  postPartnerStaffStationSignOnRequest,
  postPartnerStaffStationSignOnResponse,
  postPartnerStaffStationSignOffRequest,
  postPartnerStaffStationSignOffResponse,
  getPartnerStaffStationSignOnReset,
} = partnerStaffStationSignOnSlice.actions

export default partnerStaffStationSignOnSlice.reducer
