import { PROVIDER_OFFER_API_URL } from './types'
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchOffers = createAsyncThunk(
  'offers/fetchOffers',
  async (providerId: string, { getState, requestId }) => {
    // @ts-ignore
    const { currentRequestId, loading } = getState().offerReducer
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const asyncResult = await axios.get('/reward-service' + PROVIDER_OFFER_API_URL.replace(':providerId', providerId))
    return asyncResult.data
  },
)

export const fetchOfferById = createAsyncThunk(
  'offers/fetchOfferById',
  async ({ providerId, offerId }: { providerId: string; offerId: string }, { getState, requestId }) => {
    // @ts-ignore
    const { currentRequestId, loading } = getState().offerReducer
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const asyncResult = await axios.get(
      '/reward-service' + PROVIDER_OFFER_API_URL.replace(':providerId', providerId) + '/' + offerId,
    )
    return asyncResult.data
  },
)
