import { PartnerState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { fetchPartnerById, fetchPartners } from './thunk'

const initialState: PartnerState = {
  success: false,
  error: null,
  partners: null,
  partner: null,
  loading: 'idle',
  currentRequestId: undefined,
}

const partnerSlice = createSlice({
  name: 'partnerReducer',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPartners.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchPartners.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.partners = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchPartners.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
    [fetchPartnerById.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchPartnerById.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.partner = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchPartnerById.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
  },
})

export const selectPartners = (state: RootState) => state.partnerReducer.partners
export const selectPartnersLoading = (state: RootState) => state.partnerReducer.loading === 'pending'
export const selectPartner = (state: RootState) => state.partnerReducer.partner
export const selectPartnerLoading = (state: RootState) => state.partnerReducer.loading === 'pending'

export default partnerSlice.reducer
