import React, { useState } from 'react'
import { Button, Grid } from '@mui/material'
import { TextField } from '../final-form/TextField'
import FormLabel from './form-label'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'

export interface GeoReferenceProps {
  label: string
  disabled: boolean
  isRequired?: boolean
  labelName: string
}

const GeoReferenceField: React.FC<GeoReferenceProps> = (props) => {
  const { label, disabled, labelName } = props

  const [openMap, setOpenMap] = useState<boolean>(false)

  const openMapPreview = () => {
    console.log(openMap)
    setOpenMap(true)
  }

  /*  const closeMapPreview = () => {
    setOpenMap(false)
  }*/

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={2} md={2}>
          <FormLabel id={'labelId'} label={label} />
        </Grid>

        <Grid item xs={3} md={3}>
          <TextField
            name="longitude"
            id="longitude"
            label="Longitude"
            required={false}
            variant="outlined"
            type="number"
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={3} md={3}>
          <TextField
            name="latitude"
            id="latitude"
            label="Latitude"
            required={false}
            variant="outlined"
            type="number"
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={4} md={4} style={{ display: 'flex' }}>
          <TextField name="mapLabel" id={'mapLabel'} label={labelName} variant="outlined" disabled={disabled} />
          <Button
            data-cy="Preview"
            id="Preview"
            variant="outlined"
            style={{ maxWidth: 60, minWidth: 60, marginTop: 10, marginLeft: 10 }}
            onClick={openMapPreview}
            startIcon={
              <RemoveRedEyeOutlinedIcon
                style={{ marginTop: 0, paddingLeft: 10, width: 30, height: 30, color: '#147AC6' }}
              />
            }
          />
        </Grid>
      </Grid>
    </>
  )
}

export default GeoReferenceField
