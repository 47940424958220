import React, { useState } from 'react'
import homeIcon from '../../assets/svg/header-icon-home.svg'
import { useNavigate } from 'react-router'
import { getAuth, signOut } from 'firebase/auth'
import Loading from '../../components/ui/Loading'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import exitIcon from '../../assets/svg/Exit.svg'
import userIcon from '../../assets/svg/Users.svg'
import facilitiesIcon from '../../assets/svg/Facilities.svg'
import partnersIcon from '../../assets/svg/Partners.svg'
import activityIcon from '../../assets/svg/Activity.svg'
import offersIcon from '../../assets/svg/Offers.svg'

interface IProps {}

const Home: React.FC<IProps> = (props) => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false)

  const onClickUsers = () => {
    navigate('/users')
  }

  const onClickFacilities = () => {
    navigate('/facilities')
  }

  const onClickOffers = () => {
    navigate('/offers')
  }

  const onClickActivity = () => {
    navigate('/activities')
  }

  const onClickPartners = () => {
    navigate('/partners')
  }

  const onExit = () => {
    setLoading(true)
    const auth = getAuth()
    setTimeout(() => {
      signOut(auth)
        .then(() => {
          setLoading(false)
          navigate('/login')
        })
        .catch((error) => {
          setLoading(false)
        })
    }, 500)
  }

  return (
    <>
      <div id="wrapper" className="stations-screen">
        {loading && <Loading />}
        <div id="header-wrapper" className="landing-page-header">
          <div id="header">
            <div className="home-icon" onClick={() => navigate('/home')}>
              <img src={homeIcon} alt={'user-icon'} />
            </div>
            <div className="header-right">
              <div id="valido-logo" onClick={() => navigate('/home')}>
                <span>Valido</span>
              </div>
            </div>
          </div>
        </div>

        <div id="main-no-grid">
          <div className="grid-home pin-buttons">
            <div className="home-button " onClick={() => onClickFacilities()}>
              <div className="home-content-center">
                <img
                  src={facilitiesIcon}
                  className="home-exit-icon"
                  style={{ verticalAlign: 'middle' }}
                  alt={'Facilities'}
                />
                <span style={{ verticalAlign: 'middle', paddingLeft: 0 }}>Facilities</span>
                <ArrowRightIcon
                  fontSize={'large'}
                  style={{ verticalAlign: 'middle', float: 'right', paddingRight: 8, paddingTop: 40 }}
                />
              </div>
            </div>

            <div className="home-button " onClick={() => onClickPartners()}>
              <div className="home-content-center">
                <img
                  src={partnersIcon}
                  className="home-exit-icon"
                  style={{ verticalAlign: 'middle' }}
                  alt={'Partners'}
                />
                <span style={{ verticalAlign: 'middle', paddingLeft: 0 }}>Partners</span>
                <ArrowRightIcon
                  fontSize={'large'}
                  style={{ verticalAlign: 'middle', float: 'right', paddingRight: 8, paddingTop: 40 }}
                />
              </div>
            </div>

            <div className="home-button " onClick={() => onClickUsers()}>
              <div className="home-content-center">
                <img src={userIcon} className="home-exit-icon" style={{ verticalAlign: 'middle' }} alt={'Users'} />
                <span style={{ verticalAlign: 'middle', paddingLeft: 0 }}>Users</span>
                <ArrowRightIcon
                  fontSize={'large'}
                  style={{ verticalAlign: 'middle', float: 'right', paddingRight: 8, paddingTop: 40 }}
                />
              </div>
            </div>

            <div className="home-button " onClick={() => onClickOffers()}>
              <div className="home-content-center">
                <img src={offersIcon} className="home-exit-icon" style={{ verticalAlign: 'middle' }} alt={'Offers'} />
                <span style={{ verticalAlign: 'middle', paddingLeft: 0 }}>Offers</span>
                <ArrowRightIcon
                  fontSize={'large'}
                  style={{ verticalAlign: 'middle', float: 'right', paddingRight: 8, paddingTop: 40 }}
                />
              </div>
            </div>

            <div className="home-button " onClick={() => onClickActivity()}>
              <div className="home-content-center">
                <img
                  src={activityIcon}
                  className="home-exit-icon"
                  style={{ verticalAlign: 'middle' }}
                  alt={'Activity'}
                />
                <span style={{ verticalAlign: 'middle', paddingLeft: 0 }}>Activity</span>
                <ArrowRightIcon
                  fontSize={'large'}
                  style={{ verticalAlign: 'middle', float: 'right', paddingRight: 8, paddingTop: 40 }}
                />
              </div>
            </div>

            <div className="home-button exit-background " onClick={() => onExit()}>
              <div className="home-content-center">
                <img src={exitIcon} className="home-exit-icon" style={{ verticalAlign: 'middle' }} alt={'Exit'} />
                <span style={{ verticalAlign: 'middle', paddingLeft: 0, color: '#FFFFFF' }}>Exit</span>
                <ArrowRightIcon
                  fontSize={'large'}
                  style={{ verticalAlign: 'middle', float: 'right', paddingRight: 8, paddingTop: 40, fill: '#FFFFFF' }}
                />
              </div>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      </div>
    </>
  )
}

export default Home
