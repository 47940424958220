import React, { useEffect, useState } from 'react'
import homeIcon from '../../assets/svg/header-icon-home.svg'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store'
import { shallowEqual, useSelector } from 'react-redux'
import { selectProviderId } from '../../store/reducers/auth/types'
import { fetchUserById } from './thunk'
import { PartnerUser, partnerUserRoles, StaffClass, staffClasses } from './types'
import { Lifecycle, LifecycleOnly } from '../../store/reducers/common/types'
import { useNavigate } from 'react-router'
import { Grid } from '@mui/material'
import { TextField } from '../../components/final-form/TextField'
import FormEditToolBar from '../../components/nav/FormEditToolBar'
import { Form } from 'react-final-form'
import { Select } from '../../components/final-form/Select'
import { DatePicker } from '../../components/final-form/DatePicker'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { selectUser, selectUserLoading } from './reducers'
import Loading from '../../components/ui/Loading'

interface IProps {}

const UserForm: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch()
  const providerId = useSelector(selectProviderId)
  const navigate = useNavigate()

  let { userId } = useParams()

  const [initialValue, setInitialValue] = useState<PartnerUser>()

  const user = useAppSelector(selectUser, shallowEqual)
  const loading = useAppSelector(selectUserLoading, shallowEqual)

  useEffect(() => {
    if (providerId && userId) {
      dispatch(fetchUserById({ providerId, userId }))
    }
  }, [dispatch, providerId, userId])

  useEffect(() => {
    if (user) {
      setInitialValue(user)
    }
  }, [user])

  const onSubmit = (values: PartnerUser) => {
    console.log(values)
  }

  const onUpdateLifeCycle = (lifeCycle: Lifecycle) => {
    let lifecycleOnly: LifecycleOnly = { lifecycle: lifeCycle }
    console.log(lifecycleOnly)
  }

  const onClickCancel = () => {
    navigate('/users')
  }

  const handleBackClick = () => {
    navigate('/users')
  }
  return (
    <div id="wrapper" className="stations-screen">
      {loading && <Loading />}
      <div id="header-wrapper" className="landing-page-header">
        <div id="header">
          <div className="home-icon" onClick={() => navigate('/home')}>
            <img src={homeIcon} alt={'user-icon'} />
          </div>
          <div className="nav-icons">
            <ArrowBackIosIcon fontSize={'large'} style={{ marginRight: 20 }} onClick={() => handleBackClick()} />
          </div>
          <div className="nav-text user-avatar">Users</div>
          <div className="header-right">
            <div id="valido-logo" onClick={() => navigate('/home')}>
              <span>Valido</span>
            </div>
          </div>
        </div>
      </div>

      <Form<PartnerUser>
        onSubmit={onSubmit}
        initialValues={initialValue}
        render={({ handleSubmit, form, initialValues, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit} id="editForm" noValidate>
              <div id="main-no-grid">
                <div className="col-header">
                  <div className="text-heading-pink" style={{ paddingBottom: 8 }}>
                    User
                  </div>
                  <Grid container spacing={1}>
                    <Grid item xs={2} md={2}>
                      <TextField name="code" label="Sys Code" variant="outlined" type="text" disabled id="code" />
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <TextField name="clientRef" label="Client Ref" variant="outlined" required={true} type="text" />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextField name="firstName" label="First Name" variant="outlined" required={true} type="text" />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextField name="lastName" label="Last Name" variant="outlined" required={true} type="text" />
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Select
                        label="Role"
                        name="userRole"
                        variant="outlined"
                        required={true}
                        data={partnerUserRoles}
                        id="userRole"
                      />
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <TextField name="email" label="Email" variant="outlined" required={true} type="text" />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextField name="phone" label="Phone" variant="outlined" required={true} type="text" />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Select
                        label="Staff Class"
                        name="staffClass"
                        variant="outlined"
                        required={true}
                        data={staffClasses}
                        id="staffClass"
                      />
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <DatePicker
                        name="contractEndDate"
                        label="Contract Ends"
                        format="dd/MM/yyyy"
                        required={values.staffClass === StaffClass.Contract}
                        disabled={values.staffClass !== StaffClass.Contract}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
              <FormEditToolBar
                onUpdateLifeCycle={onUpdateLifeCycle}
                onClickCancel={onClickCancel}
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
                defaultLifeCycle={initialValues?.lifecycle}
              />
            </form>
          )
        }}
      />
    </div>
  )
}

export default UserForm
