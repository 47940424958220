import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import logger from 'redux-logger'
import { ThunkAction } from 'redux-thunk'
import appConfigSliceReducer from './reducers/app/reducers'
import stationsSliceReducer from '../pages/partners/reducers'
import notificationsSliceReducer from './reducers/notifications/reducers'
import partnerStaffSliceReducer from './reducers/partner-staffs/reducers'
import staffsSliceReducer from './reducers/staff/reducers'
import authSliceReducer from './reducers/auth/reducers'
import usersSliceReducer from '../pages/users/reducers'
import facilitySliceReducer from '../pages/facilities/reducers'
import partnerSliceReducer from '../pages/partners/reducers'
import offerSliceReducer from '../pages/offers/reducers'
import partnerStaffStationSignOnSliceReducer from './reducers/station-staff/reducers'

export const rootReducer = combineReducers({
  appConfigReducer: appConfigSliceReducer,
  stationsReducer: stationsSliceReducer,
  notificationsReducer: notificationsSliceReducer,
  partnerStaffReducer: partnerStaffSliceReducer,
  staffsReducer: staffsSliceReducer,
  usersReducer: usersSliceReducer,
  facilityReducer: facilitySliceReducer,
  partnerReducer: partnerSliceReducer,
  offerReducer: offerSliceReducer,
  authReducer: authSliceReducer,
  partnerStaffStationSignOnReducer: partnerStaffStationSignOnSliceReducer,
})

export type RootState = ReturnType<typeof rootReducer>

const store = configureStore({
  reducer: rootReducer,
  preloadedState: undefined,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(logger),
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type AppThunk = ThunkAction<void, RootState, unknown, AnyAction>

export default store
