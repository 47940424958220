import { createSlice } from '@reduxjs/toolkit'
import { PartnerStaffState } from './types'

const initialState: PartnerStaffState = {
  loading: false,
  success: false,
  error: null,
  partnerStaffs: null,
}

const partnerStaffSlice = createSlice({
  name: 'partnerStaffReducer',
  initialState,
  reducers: {
    getPartnerStaffRequest: (state) => {
      state.loading = true
      state.error = null
    },
    getPartnerStaffSuccess: (state, action) => {
      state.loading = false
      state.partnerStaffs = action.payload
    },
    getPartnerStaffError: (state, action) => {
      state.loading = false
      state.error = action.payload.error
    },
    getPartnerStaffReset: (state) => {
      state = initialState
    },
  },
})

export const { getPartnerStaffRequest, getPartnerStaffSuccess, getPartnerStaffError, getPartnerStaffReset } =
  partnerStaffSlice.actions

export default partnerStaffSlice.reducer
